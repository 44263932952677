import React, { Fragment, useEffect, useState } from "react";
import Header from "@ui/ComponentUtils/Header";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import Label from "@ui/components/Label";
import { Grid } from "@material-ui/core/index";
import {
  formatDateToRead,
  generatePDF,
  getProductImageUri,
  isAdmin,
  isVendor,
} from "@ui/Utils/helper";
import { defaultFilter } from "../Detail";
import { getSingleRecord, search } from "@ui/ComponentUtils/blueprintAPIs";
import { getQuotationPdfReport } from "@ui/views/vendor-portal/Quotation/api";
import Emitter from "@ui/Utils/CustomEventEmitter";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const DetailHeader = ({ loading, setLoading, initialValues, ...props }) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const { watch } = formMethods;
  const values = watch();
  const model = enums.models["e-creatives"]?.catalogueQuotations;
  const params = useParams();
  const navigate = useNavigate();

  const [setting, setSetting] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setSetting(
          (await getSingleRecord(enums.models["vendor-portal"]?.settings)) || {}
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const links = [
    {
      name: "Quote it",
      url: "#/" + model,
      isDisplayLink: true,
    },
    {
      name: `${values.quotationNo || ""}`,
      url: "",
      isDisplayLink: false,
    },
  ];

  // Core function to handle API call and PDF generation

  const generatePdfForVendor = async (
    vendorCode,
    vendorItems,
    tempSetting,
    vendorWise,
    zip = null // Add zip as an optional parameter
  ) => {
    let totalGrossWeight = 0,
      totalNetWeight = 0,
      totalQuantity = 0;

    const pdfProducts = vendorItems.map((item) => {
      item = item || {};
      item.product = item.product || {};

      totalGrossWeight += Number(item.product?.grossWeight) || 0;
      totalNetWeight += Number(item.product?.netWeight) || 0;
      totalQuantity += Number(item.quantity) || 0;

      return {
        styleNo: item.product?.styleNo,
        grossWeight: (Number(item.product?.grossWeight) || 0).toFixed(3),
        netWeight: (Number(item.product?.netWeight) || 0).toFixed(3),
        purity: item.product?.purity?.value
          ? item.product?.purity?.value + " KT"
          : "",
        color: item.product?.color,
        adminRemarks: item.product?.remarks,
        dueDate: item?.dueDate ? formatDateToRead(item?.dueDate) : "",
        image: getProductImageUri(item.product || {}),
        quantity: item?.quantity,
        motiWeight: item?.product?.motiWeight,
        kundanWeight: item?.product?.kundunWeight,
      };
    });

    const orderDate = values.dateCreated
      ? formatDateToRead(values.dateCreated)
      : "";

    const res = await getQuotationPdfReport({
      data: {
        vendorCode: vendorCode || "-",
        remarks: values.overallRemarks,
        orderId: values.quotationNo,
        orderDate: orderDate,
        products: pdfProducts,
        totalGrossWeight: totalGrossWeight.toFixed(3),
        totalNetWeight: totalNetWeight.toFixed(3),
        totalQuantity: totalQuantity,
        customerName: vendorWise
          ? ""
          : isAdmin
          ? `${values.createdBy?.firstName} ${values.createdBy?.lastName}`
          : "",
      },
      pdfTitle: "Report",
      template: tempSetting.pdfTemplate,
    });

    const fileName = `${vendorCode === "-" ? "NoVendor" : vendorCode}_${
      values.quotationNo
    }.pdf`;

    if (vendorWise && zip) {
      // If vendorWise is true and zip is provided, add PDF to ZIP
      const binaryData = atob(res); // Decode base64 to binary
      zip.file(fileName, binaryData, { binary: true });
    } else {
      // Otherwise, generate and download a single PDF
      generatePDF(res, fileName);
    }
  };

  // Main function to decide vendor-wise or combined download
  const downloadPdf = async (vendorWise = false) => {
    try {
      const tempSetting = { ...setting };

      if (!tempSetting?._id || !tempSetting?.pdfTemplate) {
        return Emitter.emit(
          "alert_error",
          "Configure PDF Settings to continue"
        );
      }

      const filteredItems = values.items?.filter((item) => defaultFilter(item));

      if (!filteredItems?.length) {
        return Emitter.emit(
          "alert_error",
          "No items available for PDF generation"
        );
      }

      if (vendorWise) {
        // Group items by vendorCode
        const groupedItemsByVendor = filteredItems.reduce((grouped, item) => {
          const vendorCode = item.vendorCode || "-";
          if (!grouped[vendorCode]) {
            grouped[vendorCode] = [];
          }
          grouped[vendorCode].push(item);
          return grouped;
        }, {});

        // Create a new JSZip instance
        const zip = new JSZip();

        // Generate all PDFs and add them to the ZIP
        await Promise.all(
          Object.entries(groupedItemsByVendor).map(
            ([vendorCode, vendorItems]) =>
              generatePdfForVendor(
                vendorCode,
                vendorItems,
                tempSetting,
                vendorWise,
                zip // Pass the zip instance
              )
          )
        );

        // Once all PDFs are added, generate and download the ZIP file
        const zipContent = await zip.generateAsync({ type: "blob" });
        saveAs(zipContent, `VendorWise_${values.quotationNo}.zip`);
      } else {
        // Combine all items in a single PDF
        await generatePdfForVendor("", filteredItems, tempSetting, false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const actions = [
    {
      label: "Vendor-wise PDF",
      tooltip: setting.pdfTemplate
        ? "Vendor-wise PDF"
        : "Select PDF Template in Settings",
      iconName: "pdf",
      hidden: isEditable || isVendor,
      disabled: setting.pdfTemplate ? false : true,
      onClick: () => downloadPdf(true),
    },
    {
      label: "Download PDF",
      tooltip: setting.pdfTemplate
        ? "Download PDF"
        : "Select PDF Template in Settings",
      iconName: "pdf",
      hidden: isEditable,
      disabled: setting.pdfTemplate ? false : true,
      onClick: () => downloadPdf(),
    },
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden:
        isEditable ||
        !values.items?.filter((item) => {
          return defaultFilter(item);
        }).length,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  if (loading) return "";

  const headerValueStyle = {
    fontWeight: "bold",
  };

  return (
    <>
      <Header links={links} pageTitle={"Quote it"} actions={actions} />

      <Grid container spacing={3} style={{ marginBottom: "24px" }}>
        <Grid item xs={3}>
          <Label
            label={"Quotation No"}
            value={values.quotationNo}
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={3}>
          <Label
            label={"Customer"}
            value={
              values.customer?.companyDetails?.name ||
              (values.customer?.firstName || "") +
                " " +
                (values.customer?.lastName || "")
            }
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={3}>
          <Label
            label={"Overall Remarks"}
            value={values.overallRemarks}
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={3}>
          <Label
            label={"Order By"}
            value={`${
              (values.createdBy?.firstName || "") +
              " " +
              (values.createdBy?.lastName || "") +
              ` (${values.createdBy?.phone})`
            }`}
            valueStyle={headerValueStyle}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DetailHeader;
