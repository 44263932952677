import React, { Fragment, useEffect, useState } from "react";
import Header from "@ui/ComponentUtils/Header";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import Label from "@ui/components/Label";
import { Grid } from "@material-ui/core/index";
import priceBreakUpIcon from "../../../../../../@ui/assets/e-creatives/orders/priceBreakup.svg";
import {
  formatDateToRead,
  generatePDF,
  getCleanedObjectWithTheseKeys,
  getNestedObjValue,
  getProductImageUri,
  isAdmin,
  isDivisionControlled,
  isVendor,
  oopsNotFound,
  roundOff,
  selectedDivisionId,
  selectedDivisionName,
} from "@ui/Utils/helper";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core/index";
import { defaultFilter } from "../Detail";
import {
  getAvailableProductsQuantity,
  getSingleRecord,
  search,
} from "@ui/ComponentUtils/blueprintAPIs";
import { getQuotationPdfReport } from "@ui/views/vendor-portal/Quotation/api";
import Emitter from "@ui/Utils/CustomEventEmitter";
import { Typography } from "@material-ui/core/index";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import commonConfig from "config/commonConfig";
import { Dialog } from "@material-ui/core/index";
import {
  MyCarousel,
  MyDialogContent,
  MyDialogTitle,
} from "@ui/MuiComponents/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip } from "@material-ui/core/index";
import RHAsyncAutoComplete from "@ui/ReactHookFormControls/RHAsyncAutoComplete";
import "./divisionAnimation.css";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import ProductDetailPopup from "@ui/MuiComponents/Carousel/ProductDetailPopup";

// const columns = [
//   { label: "Product", field: "product.styleNo" },
//   { label: "Category", field: "product.category.name" },
//   { label: "Image", field: "product.image" },
//   { label: "Melting", field: "melting" },
//   { label: "Screw", field: "screw" },
//   { label: "Size", field: "size" },
//   { label: "Color", field: "color" },
//   { label: "Remarks", field: "remarks" },
//   { label: "Quantity", field: "quantity" },
// ];

const DetailHeader = ({ loading, setLoading, initialValues, ...props }) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const { watch, setValue } = formMethods;
  const values = watch();
  const customerId = values.createdBy?._id;
  const model = enums.models["e-creatives"]?.linkItOrders;
  const params = useParams();
  const navigate = useNavigate();
  const [vendorCodes, setVendorCodes] = useState([]);
  const [setting, setSetting] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const res =
          (await search(enums.models["vendor-portal"]?.vendorCodes)) || [];
        setVendorCodes(res?.[0]?.availableCodes || []);
      } catch (err) {
        console.log(err);
      }
    })();

    (async () => {
      try {
        setSetting(
          (await getSingleRecord(enums.models["vendor-portal"]?.settings)) || {}
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + model);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const links = [
    {
      name: enums.ordersType.customerOrder,
      url: "#/" + model,
      isDisplayLink: true,
    },
    {
      name: `${values.orderId || ""}`,
      url: "",
      isDisplayLink: false,
    },
  ];

  // Core function to handle API call and PDF generation
  const generatePdfForVendor = async (
    vendorCode,
    vendorItems,
    tempSetting,
    vendorWise,
    zip = null // Add zip as an optional parameter
  ) => {
    let totalGrossWeight = 0,
      totalNetWeight = 0,
      totalQuantity = 0;

    const pdfProducts = vendorItems.map((item) => {
      item = item || {};
      item.product = item.product || {};

      totalGrossWeight += Number(item.product?.grossWeight) || 0;
      totalNetWeight += Number(item.product?.netWeight) || 0;
      totalQuantity += Number(item.quantity) || 0;

      return {
        styleNo: item.product?.styleNo,
        grossWeight: (Number(item.product?.grossWeight) || 0).toFixed(3),
        netWeight: (Number(item.product?.netWeight) || 0).toFixed(3),
        purity: item.product?.purity?.value
          ? item.product?.purity?.value + " KT"
          : "",
        color: item.product?.color,
        adminRemarks: item.product?.remarks,
        dueDate: item?.dueDate ? formatDateToRead(item?.dueDate) : "",
        image: getProductImageUri(item.product || {}),
        quantity: item?.quantity,
        motiWeight: item?.product?.motiWeight,
        kundanWeight: item?.product?.kundunWeight,
      };
    });

    const orderDate = values.dateCreated
      ? formatDateToRead(values.dateCreated)
      : "";

    const res = await getQuotationPdfReport({
      data: {
        vendorCode: vendorCode || "-",
        remarks: values.overallRemarks,
        orderId: values.orderId,
        orderDate: orderDate,
        products: pdfProducts,
        totalGrossWeight: totalGrossWeight.toFixed(3),
        totalNetWeight: totalNetWeight.toFixed(3),
        totalQuantity: totalQuantity,
        customerName: vendorWise
          ? ""
          : isAdmin
          ? `${values.createdBy?.firstName} ${values.createdBy?.lastName}`
          : "",
      },
      pdfTitle: "Report",
      template: tempSetting.pdfTemplate,
    });

    const fileName = `${vendorCode === "-" ? "NoVendor" : vendorCode}_${
      values.orderId
    }.pdf`;

    if (vendorWise && zip) {
      // If vendorWise is true and zip is provided, add PDF to ZIP
      const binaryData = atob(res); // Decode base64 to binary
      zip.file(fileName, binaryData, { binary: true });
    } else {
      // Otherwise, generate and download a single PDF
      generatePDF(res, fileName);
    }
  };

  // Main function to decide vendor-wise or combined download
  const downloadPdf = async (vendorWise = false) => {
    try {
      const tempSetting = { ...setting };

      if (!tempSetting?._id || !tempSetting?.pdfTemplate) {
        return Emitter.emit(
          "alert_error",
          "Configure PDF Settings to continue"
        );
      }

      const filteredItems = values.items?.filter((item) => defaultFilter(item));

      if (!filteredItems?.length) {
        return Emitter.emit(
          "alert_error",
          "No items available for PDF generation"
        );
      }

      if (vendorWise) {
        // Group items by vendorCode
        const groupedItemsByVendor = filteredItems.reduce((grouped, item) => {
          const vendorCode = item.vendorCode || "-";
          if (!grouped[vendorCode]) {
            grouped[vendorCode] = [];
          }
          grouped[vendorCode].push(item);
          return grouped;
        }, {});

        // Create a new JSZip instance
        const zip = new JSZip();

        // Generate all PDFs and add them to the ZIP
        await Promise.all(
          Object.entries(groupedItemsByVendor).map(
            ([vendorCode, vendorItems]) =>
              generatePdfForVendor(
                vendorCode,
                vendorItems,
                tempSetting,
                vendorWise,
                zip // Pass the zip instance
              )
          )
        );

        // Once all PDFs are added, generate and download the ZIP file
        const zipContent = await zip.generateAsync({ type: "blob" });
        saveAs(zipContent, `VendorWise_${values.orderId}.zip`);
      } else {
        // Combine all items in a single PDF
        await generatePdfForVendor("", filteredItems, tempSetting, false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const actions = [
    {
      label: "Vendor-wise PDF",
      tooltip: setting.pdfTemplate
        ? "Vendor-wise PDF"
        : "Select PDF Template in Settings",
      iconName: "pdf",
      hidden: isEditable || isVendor,
      disabled: setting.pdfTemplate ? false : true,
      onClick: () => downloadPdf(true),
    },
    {
      label: "Download PDF",
      tooltip: setting.pdfTemplate
        ? "Download PDF"
        : "Select PDF Template in Settings",
      iconName: "pdf",
      hidden: isEditable,
      disabled: setting.pdfTemplate ? false : true,
      onClick: () => downloadPdf(),
    },
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden:
        isEditable ||
        !values.items?.filter((item) => {
          return defaultFilter(item);
        }).length,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  if (loading) return "";

  const headerValueStyle = {
    fontWeight: "bold",
  };

  return (
    <>
      <Header links={links} pageTitle={"LINK it"} actions={actions} />

      <Grid container spacing={3} style={{ marginBottom: "24px" }}>
        <Grid item xs={3}>
          <Label
            label={"Order ID"}
            value={values.orderId}
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={3}>
          <Label
            label={"Overall Remarks"}
            value={values.overallRemarks}
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={3}>
          <Label
            label={"Order By"}
            value={`${
              values.createdBy?.firstName + " " + values.createdBy?.lastName
            }`}
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={3}>
          <Label
            label={"Contact"}
            value={values.createdBy?.phone}
            valueStyle={headerValueStyle}
          />
        </Grid>
      </Grid>

      {(values.items?.filter((item) => defaultFilter(item)) || []).length ? (
        (values.items || []).map(
          (item, itemIndex) =>
            defaultFilter(item) && (
              <ProductItem
                key={itemIndex}
                item={item}
                itemIndex={itemIndex}
                isEditable={isEditable}
                customerId={customerId}
                vendorCodes={vendorCodes}
                initialValues={initialValues}
              />
            )
        )
      ) : (
        <div style={{ textAlign: "center" }}>
          <div>
            <img
              src={oopsNotFound}
              style={{ height: "320px", width: "440px" }}
            />
          </div>
          <div>
            <h3>{`No products were ordered in ${selectedDivisionName} Division`}</h3>
          </div>
        </div>
      )}
    </>
  );
};

const diamondColumns = [
  { label: "Sr No", field: "srNo" },
  { label: "Quantity", field: "diamondQuantity" },
  { label: "Total Carat (cts)", field: "totalCarat" },

  { label: "Shape", field: "shape" },
  { label: "Color", field: "color" },

  { label: "Solitaire Length (mm)", field: "solitaireSize.length" },
  { label: "Solitaire Width (mm)", field: "solitaireSize.width" },

  { label: "Sieve Length (mm)", field: "sieveSize.length" },
  { label: "Sieve Width (mm)", field: "sieveSize.width" },

  { label: "Cut", field: "cut" },
  { label: "Clarity", field: "clarity" },

  { label: "Polish", field: "polish" },
  { label: "Symmetry", field: "symmetry" },
  { label: "Fluorescence Intensity", field: "fluorescenceIntensity" },
  { label: "Grading Lab", field: "gradingLab" },
];

const ProductItem = ({
  item,
  itemIndex,
  isEditable,
  vendorCodes = [],
  customerId,
  initialValues,
}) => {
  const sameDivision = isDivisionControlled
    ? selectedDivisionId === item?.product?.division?._id || // remove this line after 1 PROD Migration for existing clients... #11th March, 2024
      selectedDivisionId === item?.division?._id
    : true;

  isEditable = isDivisionControlled
    ? sameDivision
      ? isEditable
      : false
    : isEditable;

  const formMethods = useFormContext();
  const { setValue } = formMethods;
  const productSearchUrl =
    enums.models.products + "/search?hook=true&priceAfterDiscount=true";
  const lockedFilterCriteria = {
    $or: [
      {
        "lockedFrom.orderPro": { $exists: false },
      },
      { "lockedFrom.orderPro": customerId },
    ],
  };
  const [uiConsoleObject, setUiConsoleObject] = useState(null);
  const [stockAvailable, setStockAvailable] = useState(0);
  const [openBreakup, setOpenBreakup] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [issuedStocksOptions, setIssuedStocksOptions] = useState([]);
  const isCustomized = item.isCustomized;
  const originalItem = initialValues?.items?.[itemIndex] || {};
  const isDelivered =
      item.orderStatus === enums["vendor-portal"].orderStatus.delivered,
    isRepair = item.orderStatus === enums["vendor-portal"].orderStatus.repair,
    isReturned =
      item.orderStatus === enums["vendor-portal"].orderStatus.returned;

  const isAvailable = stockAvailable >= item.quantity;
  const headingColor =
    isCustomized === enums.customizationType.product
      ? { backgroundColor: "black", color: "white" }
      : isCustomized === enums.customizationType.scratch
      ? { backgroundColor: "#a86d32", color: "white" }
      : { backgroundColor: "green", color: "white" };

  const [openImagePreview, setOpenImagePreview] = useState();
  const stockImageLocation =
    isCustomized === enums.customizationType.scratch
      ? item.images?.[0]?.signedUrl
      : getProductImageUri(item?.product || {});

  useEffect(() => {
    (async () => {
      try {
        if (item?.product?.styleNo) {
          setStockAvailable(
            (await getAvailableProductsQuantity({
              styleNo: item.product?.styleNo,
            })) || "-"
          );
        }
      } catch (err) {
        console.log(err);
      }
    })();

    if (item.product?._id) {
      setUiConsoleObject(
        getCleanedObjectWithTheseKeys(item?.product, {
          keysToRemove: [
            "_id",
            "status",
            "code",
            "client",
            "division",
            "createdBy",
            "modifiedBy",
            "dateCreated",
            "dateModified",
            "imageFlag",
            "divaSynced",
            "catalogues",
            "printHistory",
            "packagingHistory",
          ],
          removeNullValues: true,
        })
      );
    }
  }, [item?.product]);

  useEffect(() => {
    if (isReturned && !issuedStocksOptions.length) {
      setIssuedStocksOptions([
        ...(originalItem.issuedStocks || []),
        ...(originalItem.repairStocks || []),
      ]);
    }
  }, [isReturned, item.issuedStocks]);

  const getWorkflowOptions = () => {
    return Object.values(enums["vendor-portal"].orderStatus).filter(
      (oStatus) => {
        if (oStatus === enums["vendor-portal"].orderStatus.repair)
          return (
            originalItem.orderStatus ===
              enums["vendor-portal"].orderStatus.delivered ||
            originalItem.orderStatus ===
              enums["vendor-portal"].orderStatus.repair ||
            originalItem.orderStatus ===
              enums["vendor-portal"].orderStatus.returned
          );
        else if (oStatus === enums["vendor-portal"].orderStatus.returned)
          return (
            originalItem.orderStatus ===
              enums["vendor-portal"].orderStatus.delivered ||
            originalItem.orderStatus ===
              enums["vendor-portal"].orderStatus.repair ||
            originalItem.orderStatus ===
              enums["vendor-portal"].orderStatus.returned
          );

        switch (originalItem?.orderStatus) {
          case enums["vendor-portal"].orderStatus.inReview:
            return true;
          case enums["vendor-portal"].orderStatus.accepted:
            return oStatus !== enums["vendor-portal"].orderStatus.inReview;

          case enums["vendor-portal"].orderStatus.making:
            return !(
              oStatus === enums["vendor-portal"].orderStatus.accepted ||
              oStatus === enums["vendor-portal"].orderStatus.inReview
            );
          case enums["vendor-portal"].orderStatus.dispatched:
            return !(
              oStatus === enums["vendor-portal"].orderStatus.making ||
              oStatus === enums["vendor-portal"].orderStatus.accepted ||
              oStatus === enums["vendor-portal"].orderStatus.inReview
            );
          case enums["vendor-portal"].orderStatus.delivered:
            return (
              oStatus === enums["vendor-portal"].orderStatus.repair ||
              oStatus === enums["vendor-portal"].orderStatus.delivered ||
              oStatus === enums["vendor-portal"].orderStatus.returned
            );
          case enums["vendor-portal"].orderStatus.repair:
            return (
              oStatus === enums["vendor-portal"].orderStatus.repair ||
              oStatus === enums["vendor-portal"].orderStatus.delivered ||
              oStatus === enums["vendor-portal"].orderStatus.returned
            );
          case enums["vendor-portal"].orderStatus.returned:
          case enums["vendor-portal"].orderStatus.canceled:
            return true;
        }
      }
    );
  };
  return (
    <MainSection
      title={
        <Grid container spacing={2}>
          <Grid item xs={isCustomized ? 10 : 12}>
            <h3
              style={{
                ...headingColor,
                textAlign: "center",
                height: 50,
                paddingTop: 15,
              }}
            >
              {isCustomized
                ? `${
                    itemIndex + 1
                  }. Customized from ${isCustomized.toUpperCase()}`
                : `${itemIndex + 1}. ORIGINAL Product`}
            </h3>
          </Grid>
          {isCustomized ? (
            <Grid item xs={2}>
              <Tooltip title="View Customizations">
                <VisibilityIcon
                  onClick={() => setOpenDetails(true)}
                  style={{ fontSize: 50, cursor: "pointer", marginTop: "16px" }}
                />
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
      }
      titleStyle={{ color: "black" }}
      style={{ marginBottom: "24px", opacity: sameDivision ? 1 : 0.5 }}
      actions={[
        {
          buttonType: "custom",
          customIcon: (
            <div
              style={{
                position: "relative",
                display: "inline-flex",
                alignItems: "center",
                padding: "8px 12px",
                borderRadius: "12px",
                backgroundColor: sameDivision ? "black" : "grey",
                color: sameDivision ? "gold" : "white",
                fontSize: "14px",
                fontWeight: "bold",
                overflow: "hidden", // Hide overflowing animation effects
              }}
            >
              {sameDivision ? (
                <div
                  style={{
                    animation: "shine 1.5s infinite", // Animation for the shining effect
                  }}
                >
                  ⭐ {item?.division?.name || ""}
                </div>
              ) : (
                <div>{item?.division?.name || ""}</div>
              )}
            </div>
          ),
        },
      ]}
    >
      <Grid container spacing={3} key={itemIndex} style={{ padding: "16px" }}>
        <Grid item xs={2}>
          <Label label={"SKU"} value={item?.product?.sku || "-"} />
        </Grid>

        <Grid item xs={2}>
          <Label
            label={"Gross >< Net Weight (g)"}
            value={`${
              item?.product?.grossWeight
                ? roundOff(item.product.grossWeight, { weight: true })
                : "-"
            } >< ${
              item?.product?.netWeight
                ? roundOff(item.product.netWeight, { weight: true })
                : "-"
            }`}
          />
        </Grid>

        <Grid item xs={2}>
          <Label
            label={"Diamond Weight (cts)"}
            value={
              item?.product?.totalDiamondWeight
                ? roundOff(item.product.totalDiamondWeight, {
                    weight: true,
                    isDiamond: true,
                  })
                : ""
            }
          />
        </Grid>

        <Grid item xs={1}>
          <Label
            label={"Engraving"}
            value={`${item.product?.specificEngraving || "-"}`}
          />
        </Grid>

        <Grid item xs={1}>
          <Label
            label={"Category"}
            value={item?.product?.category?.name || "-"}
          />
        </Grid>

        <Grid item xs={1}>
          <Label
            label={"Purity"}
            value={
              item?.product?.purity?.value
                ? item?.product?.purity?.value + " KT"
                : ""
            }
          />
        </Grid>

        <Grid item xs={1}>
          <Label label={"Color"} value={item?.product?.color || "-"} />
        </Grid>

        <Grid item xs={1}>
          <Label label={"Size"} value={item?.product?.size || "-"} />
        </Grid>

        {/* <Grid item xs={1}>
          {uiConsoleObject ? (
            <Tooltip
              placement="top"
              PopperProps={{
                strategy: "fixed", // This will keep the Tooltip inside the viewport
              }}
              title={(() => {
                return <pre>{JSON.stringify(uiConsoleObject, null, 2)}</pre>;
              })()}
            >
              <TroubleshootIcon style={{ fontSize: "48px", color: "gold" }} />
            </Tooltip>
          ) : null}
        </Grid> */}

        <Grid
          item
          xs={2}
          style={{ cursor: "pointer" }}
          onClick={() => setOpenImagePreview(true)}
        >
          <img
            src={stockImageLocation}
            style={{ height: "10rem", width: "10rem", objectFit: "contain" }}
            onError={(event) => {
              event.target.src = oopsNotFound;
            }}
          />
          <pre style={{ fontSize: "12px", fontWeight: "bold" }}>
            View Details
          </pre>
        </Grid>

        {openImagePreview ? (
          <ProductDetailPopup
            open={openImagePreview}
            onClose={() => setOpenImagePreview()}
            product={item.product}
          />
        ) : null}

        <Grid item xs={10}>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <Label
                label={"Ordered Priority"}
                value={
                  isCustomized
                    ? enums.orderPriority.makeToOrder
                    : Object.values(item?.product?.lockedFrom || {}).find(
                        (app) => app
                      )
                    ? enums.orderPriority.makeToOrder
                    : enums.orderPriority.ordered
                }
              />
            </Grid>

            <Grid item xs={1}>
              <Label label={"Quantity"} value={`${item.quantity}`} />
            </Grid>

            <Grid item xs={5}></Grid>
            <Grid item xs={2}>
              <Label
                label={"Ordered Price"}
                value={`${item.product?.jewelPrice || "-"}`}
              />
            </Grid>

            {isDelivered || isReturned || isRepair ? (
              <>
                <Grid item xs={2}>
                  <Label
                    label={"Issued Price"}
                    value={`${(item.issuedStocks || []).reduce(
                      (sum, stock) => sum + (stock.jewelPrice || 0),
                      0
                    )}`}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={2}></Grid>
            )}

            <Grid item xs={4}>
              <Label
                label={"Stock Available"}
                labelStyle={{ color: isAvailable ? "blue" : "red" }}
                valueStyle={{ color: isAvailable ? "blue" : "red" }}
                value={`${stockAvailable || "0"}`}
              />
            </Grid>

            <Grid item xs={4}></Grid>

            <Grid item xs={2}>
              <Tooltip title="Ordered Price Breakup">
                <img
                  src={priceBreakUpIcon}
                  style={{ cursor: "pointer", height: "50px", width: "50px" }}
                  onClick={() => setOpenBreakup([item.product])} // sameDivision && put this in if you want to disable open price breakup for other division stocks which looks disabled
                />
              </Tooltip>
            </Grid>

            {isDelivered || isReturned || isRepair ? (
              <Grid item xs={2}>
                <Tooltip title="Issued Price Breakup">
                  <img
                    src={priceBreakUpIcon}
                    style={{
                      cursor: "pointer",
                      height: "50px",
                      width: "50px",
                      // backgroundColor: isDelivered
                      //   ? "green"
                      //   : isRepair
                      //   ? "orange"
                      //   : isReturned
                      //   ? "red"
                      //   : "black",
                    }}
                    onClick={() => setOpenBreakup(item.issuedStocks)}
                  />
                </Tooltip>
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <Grid item xs={2}>
          {isEditable && isAdmin ? (
            <RHAutoComplete
              options={vendorCodes}
              name={`items[${itemIndex}].vendorCode`}
              label={"Vendor Code"}
              value={item?.vendorCode || "-"}
              // style={{ backgroundColor: "white" }}
            />
          ) : (
            <Label label={"Vendor Code"} value={item?.vendorCode} />
          )}
        </Grid>

        {/* <Grid item xs={2}>
          {isEditable ? (
            <RHAutoComplete
              label={"Order Status"}
              options={getWorkflowOptions()}
              name={`items[${itemIndex}].orderStatus`}
              value={item?.orderStatus || "-"}
              disableClearable
              // style={{ backgroundColor: "white" }}
            />
          ) : (
            <Label label={"Order Status"} value={item?.orderStatus} />
          )}
        </Grid>

        <Grid item xs={2}>
          {isEditable && isAdmin && isCustomized && isDelivered ? (
            <RHAsyncAutoComplete
              apiUrl={productSearchUrl}
              apiBody={{
                distinct: "styleNo",
                customerId,
                ...lockedFilterCriteria,
              }}
              name={`items[${itemIndex}].product`}
              label={"Style No"}
              getOptionLabel={(opt) => opt?.styleNo}
              onBlur={() => {
                setValue(`items[${itemIndex}].issuedStocks`, []);
              }}
            />
          ) : (
            <Label label={"Style No"} value={item?.product?.styleNo || "-"} />
          )}
        </Grid>

        {isDelivered || isReturned || isRepair ? (
          <>
            <Grid item xs={2}>
              {isEditable && isAdmin && isDelivered ? (
                <RHAsyncAutoComplete
                  name={`items[${itemIndex}].issuedStocks`}
                  label={"Issued Stocks"}
                  getOptionLabel={(opt) => opt?.sku || ""}
                  multiple={true}
                  dynamicAPIbody={true}
                  getOptionSelected={(opt, val) => opt?.sku === val?.sku}
                  apiUrl={productSearchUrl}
                  apiBody={{
                    styleNo: item.product?.styleNo,
                    customerId,

                    ...{
                      ...lockedFilterCriteria,
                      $or: [
                        { stockStatus: enums.stockStatus.inventory },
                        {
                          "packagingHistory.assignee._id": customerId,
                          stockStatus: enums.stockStatus.approval,
                        },
                      ],
                    },
                  }}
                  onChange={(_, val) => {
                    let removeIndex = [],
                      removeRepairedIndex = [];

                    (val || []).map((prod) => {
                      (item.returnedStocks || []).find((stock, stockIndex) => {
                        if (prod.sku === stock.sku) {
                          removeIndex.push(stockIndex);
                          return true;
                        }
                      });

                      (item.repairStocks || []).find((stock, stockIndex) => {
                        if (prod.sku === stock.sku) {
                          removeRepairedIndex.push(stockIndex);
                          return true;
                        }
                      });
                    });

                    if (removeIndex.length) {
                      setValue(
                        `items[${itemIndex}].returnedStocks`,
                        (item.returnedStocks || []).filter((_, stockIndex) =>
                          removeIndex.includes(stockIndex) ? false : true
                        )
                      );
                    }

                    if (removeRepairedIndex.length) {
                      setValue(
                        `items[${itemIndex}].repairStocks`,
                        (item.repairStocks || []).filter((_, stockIndex) =>
                          removeRepairedIndex.includes(stockIndex)
                            ? false
                            : true
                        )
                      );
                    }

                    setValue(`items[${itemIndex}].issuedStocks`, val);
                  }}
                  // style={{ backgroundColor: "white" }}
                />
              ) : (
                <Label
                  label={"Issued Stocks"}
                  value={(item?.issuedStocks || [])
                    .map((stock) => stock?.sku)
                    .join(", ")}
                />
              )}
            </Grid>

            <Grid item xs={2}>
              {isEditable && isAdmin && isRepair ? (
                <RHAutoComplete
                  name={`items[${itemIndex}].repairStocks`}
                  label={"Repair Stocks"}
                  getOptionLabel={(opt) => opt?.sku || ""}
                  multiple={true}
                  getOptionSelected={(opt, val) => opt?.sku === val?.sku}
                  options={originalItem.issuedStocks || []}
                  onChange={(_, val) => {
                    let removeIndex = [];
                    (val || []).map((prod) => {
                      (item.issuedStocks || []).find(
                        (issuedStock, issuedStockIndex) => {
                          if (prod.sku === issuedStock.sku) {
                            removeIndex.push(issuedStockIndex);
                            return true;
                          }
                        }
                      );
                    });

                    if (removeIndex.length) {
                      setValue(
                        `items[${itemIndex}].issuedStocks`,
                        (item.issuedStocks || []).filter((_, stockIndex) =>
                          removeIndex.includes(stockIndex) ? false : true
                        )
                      );
                    }
                  }}
                />
              ) : (
                <Label
                  label={"Repair Stocks"}
                  value={(item?.repairStocks || [])
                    .map((stock) => stock?.sku)
                    .join(", ")}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              {isEditable && isAdmin && isReturned ? (
                <RHAutoComplete
                  name={`items[${itemIndex}].returnedStocks`}
                  label={"Returned Stocks"}
                  getOptionLabel={(opt) => opt?.sku || ""}
                  multiple={true}
                  getOptionSelected={(opt, val) => opt?.sku === val?.sku}
                  options={issuedStocksOptions || []}
                  onChange={(_, val) => {
                    let removeIndex = [],
                      removeRepairedIndex = [];
                    (val || []).map((prod) => {
                      (item.issuedStocks || []).find(
                        (issuedStock, issuedStockIndex) => {
                          if (prod.sku === issuedStock.sku) {
                            removeIndex.push(issuedStockIndex);
                            return true;
                          }
                        }
                      );

                      (item.repairStocks || []).find((stock, stockIndex) => {
                        if (prod.sku === stock.sku) {
                          removeRepairedIndex.push(stockIndex);
                          return true;
                        }
                      });
                    });

                    if (removeIndex.length) {
                      setValue(
                        `items[${itemIndex}].issuedStocks`,
                        (item.issuedStocks || []).filter((_, stockIndex) =>
                          removeIndex.includes(stockIndex) ? false : true
                        )
                      );
                    }

                    if (removeRepairedIndex.length) {
                      setValue(
                        `items[${itemIndex}].repairStocks`,
                        (item.repairStocks || []).filter((_, stockIndex) =>
                          removeRepairedIndex.includes(stockIndex)
                            ? false
                            : true
                        )
                      );
                    }
                  }}
                />
              ) : (
                <Label
                  label={"Returned Stocks"}
                  value={(item?.returnedStocks || [])
                    .map((stock) => stock?.sku)
                    .join(", ")}
                />
              )}
            </Grid>
          </>
        ) : null} */}
      </Grid>

      {/* Breakup </3 */}
      <Dialog
        open={openBreakup}
        onClose={() => setOpenBreakup(false)}
        maxWidth={"md"}
        fullWidth={true}
      >
        <MyDialogTitle onClose={setOpenBreakup}>Price Breakup</MyDialogTitle>
        <MyDialogContent>
          <Grid container spacing={2}>
            {(openBreakup || []).map((prod, prodIndex) => {
              return (
                prod && (
                  <Grid container style={{ padding: "24px" }} key={prodIndex}>
                    <Grid item xs={6}>
                      <Label
                        labelStyle={{ fontWeight: "bold" }}
                        valueStyle={{ fontWeight: "bold" }}
                        label={"SKU"}
                        value={prod.sku || "-"}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Label
                        label={"Price"}
                        value={prod.jewelPrice || "-"}
                        labelStyle={{ fontWeight: "bold" }}
                        valueStyle={{ fontWeight: "bold" }}
                      />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={3}>
                      <Label
                        label={"Live Gold Rate"}
                        value={prod.jewelPriceBreakup?.liveGoldPrice || "-"}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Label
                        label={"Live Pure Metal Price"}
                        value={
                          prod.jewelPriceBreakup?.livePureMetalPrice || "-"
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Label
                        label={"Gold"}
                        value={prod.jewelPriceBreakup?.gold || "-"}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Label
                        label={"Extras"}
                        value={prod.jewelPriceBreakup?.totalExtrasPrice || "-"}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Label
                        label={"Gold Labor"}
                        value={prod.jewelPriceBreakup?.goldLaborPrice || "-"}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Label
                        label={"NetWeight * GoldLabor"}
                        value={
                          prod.jewelPriceBreakup?.netWeightGoldLaborPrice || "-"
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Label
                        label={"Diamonds"}
                        value={
                          prod.jewelPriceBreakup?.diamonds?.join(", ") || "-"
                        }
                      />
                    </Grid>
                  </Grid>
                )
              );
            })}
          </Grid>
        </MyDialogContent>
      </Dialog>

      {/* Customization */}
      <Dialog
        open={openDetails}
        onClose={() => setOpenDetails(false)}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <MyDialogTitle onClose={setOpenDetails}>Customizations</MyDialogTitle>
        <MyDialogContent>
          <Grid
            container
            spacing={3}
            key={itemIndex}
            style={{ padding: "16px" }}
          >
            <Grid
              item
              xs={12}
              container
              style={{ backgroundColor: "lightblack" }}
            >
              <Grid item xs={2}>
                <Label
                  label={"Category"}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                  value={item?.category?.name || "-"}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Sub Category"}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                  value={item?.subCategory?.name}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Collectin Line"}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                  value={item?.collectionLine?.name}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Specific Engraving"}
                  value={item?.specificEngraving}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Melting"}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                  value={item?.melting}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Screw"}
                  value={item?.screw}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Size"}
                  value={item?.size}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Color"}
                  value={item?.color}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Setting"}
                  value={item.setting}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Gross Weight (g)"}
                  value={
                    item.grossWeight
                      ? roundOff(item.grossWeight, { weight: true })
                      : "-"
                  }
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>
              <Grid item xs={2}>
                <Label
                  label={"Purity"}
                  value={
                    item?.purity?.value
                      ? `${item.purity.value} KT - ${item.purity.percent} %`
                      : ""
                  }
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={2}>
                <Label
                  label={"Metal Type"}
                  value={item.metalType}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>

              <Grid item xs={4}>
                <Label
                  label={"Remarks"}
                  value={item?.remarks}
                  labelStyle={componentStyles.greenLabel}
                  valueStyle={componentStyles.greenLabel}
                />
              </Grid>
            </Grid>

            {item.diamondDetails?.length ? (
              <Grid
                item
                xs={12}
                container
                style={{
                  // backgroundColor: "lightblue",
                  padding: "16px",
                }}
              >
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Diamond Details
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Table>
                    <TableHead>
                      {diamondColumns.map((col, colIndex) => (
                        <TableCell key={colIndex}>{col.label}</TableCell>
                      ))}
                    </TableHead>
                    <TableBody>
                      {(item.diamondDetails || []).map(
                        (diamond, diamondIndex) => (
                          <TableRow key={diamondIndex}>
                            {diamondColumns.map((col, colIndex) => (
                              <TableCell key={colIndex}>
                                {colIndex === 0
                                  ? diamondIndex + 1
                                  : getNestedObjValue(diamond, col.field)}
                              </TableCell>
                            ))}
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </MyDialogContent>
      </Dialog>
    </MainSection>
  );
};

const componentStyles = {
  greenLabel: { color: "black" },
};

export default DetailHeader;
