import MainCard from "@ui/MuiComponents/Card/MainCard";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core/index";
import enums from "helpers/enums";
import { useWatch } from "react-hook-form";
import RHAsyncAutoComplete from "@ui/ReactHookFormControls/RHAsyncAutoComplete";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import _ from "lodash";
import { getProductImageUri } from "@ui/Utils/helper";
import Label from "@ui/components/Label";
import { getPaginatedData } from "@ui/ComponentUtils/blueprintAPIs";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useFormContext } from "react-hook-form";
import RHCheckbox from "@ui/ReactHookFormControls/RHCheckbox";

const DetailContent = ({ isEditable }) => {
  return (
    <>
      <ConditionsCard isEditable={isEditable} />
      {isEditable ? null : <MatchingProducts />}
    </>
  );
};

const ConditionsCard = ({ isEditable }) => {
  const values = useWatch();
  return (
    <MainSection title="Products Criteria">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          {isEditable ? (
            <RHTextField label="Style No" name={`condition.styleNo`} />
          ) : (
            <Label label={"Style No"} value={values.condition?.styleNo} />
          )}
        </Grid>
        <Grid item xs={2}>
          <Typography>Gross Weight</Typography>
          {isEditable
            ? null
            : (values.condition?.grossWeight?.from || "") +
              " - " +
              (values.condition?.grossWeight?.to || "")}
        </Grid>

        {isEditable ? (
          <>
            <Grid item xs={2}>
              <RHTextField
                label="From"
                name={`condition.grossWeight.from`}
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <RHTextField
                label="To"
                name={`condition.grossWeight.to`}
                type="number"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={4}></Grid>
        )}

        <Grid item xs={6}>
          {isEditable ? (
            <RHAsyncAutoComplete
              label="Categories"
              multiple={true}
              apiUrl={enums.models.category + "/search"}
              apiMethod={"post"}
              name={`condition.category`}
              getOptionLabel={(option) => option?.name || ""}
              getOptionSelected={(option, value) => option.name === value.name}
            />
          ) : (
            <Label
              label={"Categories"}
              value={values.condition?.category
                ?.map((cat) => cat?.name)
                .join(", ")}
            />
          )}
        </Grid>

        <Grid item xs={2}>
          <Typography>Net Weight</Typography>
          {isEditable
            ? null
            : (values.condition?.netWeight?.from || "") +
              " - " +
              (values.condition?.netWeight?.to || "")}
        </Grid>

        {isEditable ? (
          <>
            <Grid item xs={2}>
              <RHTextField
                label="From"
                name={`condition.netWeight.from`}
                type="number"
              />
            </Grid>
            <Grid item xs={2}>
              <RHTextField
                label="To"
                name={`condition.netWeight.to`}
                type="number"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={4}></Grid>
        )}

        <Grid item xs={2}>
          <RHCheckbox
            label="With Images"
            name={`condition.imageFlag`}
            color={"secondary"}
            disabled={!isEditable}
          />
        </Grid>

        {/* <Grid
              item
              xs={2}
              onMouseEnter={() => setFilterHover(true)}
              onMouseLeave={() => setFilterHover(false)}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#ffc800",
                  padding: "10px",
                  boxShadow: filterHover && "5px 10px 8px #ffc800",
                }}
                onClick={fetchAvailableProds}
              >
                Filter Products
              </Button>
            </Grid> */}
      </Grid>
    </MainSection>
  );
};

const defaultPageSize = 8;

const MatchingProducts = ({}) => {
  const formMethods = useFormContext();
  const values = formMethods.getValues();
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: defaultPageSize,
  });
  const [data, setData] = useState({
    records: [],
    metaInfo: {
      currentPageNo: 0,
      perPageRecords: defaultPageSize,
      totalPages: 1,
      totalRecords: 0,
      criteria: [],
    },
  });

  useEffect(() => {
    (async () => {
      try {
        if (values._id) {
          const res = await getPaginatedData(enums.models.products, {
            page: pagination.page,
            pageSize: pagination.pageSize,
            criteria: [{ catalogues: { $in: [values._id] } }, {}],
          });

          setData({ records: res.records, metaInfo: res.paginationInfo });
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [values.dateModified, pagination, values.frontend?.sync, values._id]);

  return (
    <MainSection
      title={data.metaInfo.totalRecords + " Designs"}
      style={{ marginTop: "24px" }}
    >
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <FirstPageIcon
            style={styles.arrow}
            onClick={() => {
              if (pagination.page - 1 < 0) {
                return;
              }
              let page = pagination.page - 1;

              setPagination({
                page: page,
                pageSize: defaultPageSize,
              });
            }}
          />
        </Grid>
        <Grid item xs={2}>
          {`${pagination.page + 1} / ${
            Math.ceil(data.metaInfo.totalRecords / defaultPageSize) || 1
          }`}{" "}
        </Grid>
        <Grid item xs={2}>
          <LastPageIcon
            style={styles.arrow}
            onClick={() => {
              if (
                pagination.page + 1 <
                Math.ceil(data.metaInfo.totalRecords / defaultPageSize)
              ) {
                setPagination({
                  page: pagination.page + 1,
                  pageSize: defaultPageSize,
                });
              }
            }}
          />
        </Grid>

        <Grid item xs={12} container spacing={4}>
          {(data.records || []).map((prod, index) => (
            <Grid item xs={3} key={index}>
              <ProductCard prod={prod} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </MainSection>
  );
};
const ProductCard = ({ prod, isEditable }) => {
  return (
    <MainCard
      fileName={prod.styleNo}
      title={prod?.styleNo}
      image={getProductImageUri(prod)}
    >
      <Typography variant="body2" color="text.secondary">
        Category: {prod?.category?.name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Gross Weight: {prod?.grossWeight?.toFixed(2)}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Net Weight: {prod?.netWeight?.toFixed(2)}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Quantity: {prod?.quantity}
      </Typography>
    </MainCard>
  );
};

const styles = {
  arrow: {
    fontSize: "50px",
    cursor: "pointer",
  },
};
export default DetailContent;
